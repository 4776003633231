import React from "react"

import {
  Container,
  Wrapper,
  Item,
  ItemsWrapper,
  ImageBox,
  TextBox,
  Date,
  Title,
  TitleWrapper,
} from "./styles"

import { useStoryData } from "shared/Hooks/OurStory/useStoryData"
import { SubHeaderTitle } from "components/Typography"

export const OurStory = () => {
  const data = useStoryData()
  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <SubHeaderTitle>Nasza historia</SubHeaderTitle>
        </TitleWrapper>

        <ItemsWrapper>
          {data.map((item, i) => (
            <Item key={i}>
              {item.icons}
              <ImageBox>{item.image}</ImageBox>
              <TextBox>
                <Date>{item.date}</Date>
                <Title>{item.title}</Title>
              </TextBox>
            </Item>
          ))}
        </ItemsWrapper>
      </Wrapper>
    </Container>
  )
}
