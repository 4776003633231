import React from "react"
import ImageIcon from "assets/img/svg/image_1.svg"
import SEO from "components/seo"
import { PageSubHeader } from "components/PageSubHeader"
import { Counts, Hero, OurMission, OurStory, Team } from "sections/About"
import { NewsSlider } from "components/NewsSlider"
import { BanerSmall } from "components/BanerSmall"
import GirlHeart from "assets/img/girl_heart.png"

const AboutPage = () => {
  return (
    <>
      <SEO
        title={"O nas"}
        description={
          "Świat Bez Barier to fundacja dla dzieci, która od czerwca 2020 roku zapewnia lepszy start w dorosłość."
        }
      />
      <Hero />
      <PageSubHeader title="Poznajmy się!" image={ImageIcon}>
        Świat Bez Barier to fundacja dla dzieci, która od czerwca 2020 roku
        zapewnia lepszy start w dorosłość. Niemożliwe dla nas nie istnieje!
        Uważamy, że wiedza otwiera świat, dlatego zapewniamy dostęp do kursów,
        szkoleń i zajęć oraz stażów i praktyk zawodowych.
      </PageSubHeader>
      <OurStory />
      <OurMission />
      <Counts />
      <Team />
      <BanerSmall
        image={GirlHeart}
        header="Chcesz nas wesprzeć w inny sposób?"
        subHeader="Skontaktuj się z nami"
        buttonText="Podejmij z nami współpracę"
        buttonIcon="heart"
        slug="/wesprzyj-nas"
      />
      <NewsSlider title="Aktualności" />
    </>
  )
}

export default AboutPage
