import React from "react"
import { SubHeaderParagraph, SubHeaderTitle } from "components/Typography"

import {
  Container,
  Wrapper,
  TextWrapper,
  ItemsWrapper,
  Item,
  ImageBox,
  Mask,
  Text,
  ButtonWrapper,
  ItemWrapper,
} from "./styles"

import { useMissionData } from "shared/Hooks/OurMission/useMissionData"
import { PrimaryButton } from "../../../components/Buttons/buttons"

export const OurMission = () => {
  const data = useMissionData()

  return (
    <Container>
      <Wrapper>
        <TextWrapper>
          <SubHeaderTitle color="#fff">Nasza misja i cele</SubHeaderTitle>
          <SubHeaderParagraph color="#fff">
            Bardzo zależy nam na tym aby dzieci otrzymały od nas duża dawkę
            wiary , motywacji i wsparcia w realizacji ich celów. Naszym głównym
            założeniem jest pomoc w realizacji obranych celów i rozwijanie
            skrzydeł. Istotą naszego działania jest lekkie nakierunkowanie
            naszych podopiecznych na lepsza drogę, pokazanie im, że przyszłość
            zależy od nas i od naszych wyborów.
          </SubHeaderParagraph>
        </TextWrapper>
        <ItemsWrapper>
          {data.map((item, i) => (
            <ItemWrapper key={i}>
              {item.icons}
              <Item key={i}>
                <ImageBox>
                  {item.image}
                  <Mask />
                  <Text>{item.title}</Text>
                </ImageBox>
              </Item>
            </ItemWrapper>
          ))}
        </ItemsWrapper>
        <ButtonWrapper>
          <PrimaryButton type="heart_with_arrow" href="/wesprzyj-nas">
            Podejmij z nami współpracę
          </PrimaryButton>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  )
}
