import { useStaticQuery, graphql } from "gatsby"

export const useStoryPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      story_1: file(relativePath: { eq: "img/AboutUs/nasza-historia-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      story_2: file(relativePath: { eq: "img/AboutUs/nasza-historia-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return query
}
