import React from "react"
import {
  BanerIcon,
  BanerIconWrapper,
  BannerHeader,
  BannerParagraph,
  ButtonWrapper,
  Container,
  Wrapper,
} from "./styles"
import { SectionWrapper } from "shared/Styles/Wrappers"
import { TitleSvg } from "components/TitleSvg"
import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import { PrimaryButton } from "components/Buttons"

const BanerSmall = ({
  image,
  header,
  subHeader,
  buttonText,
  buttonIcon,
  slug,
}) => {
  return (
    <Container>
      <SectionWrapper>
        <Wrapper>
          <TitleSvg image={PlusSvg} top="0%" right="10%" duration="5s" />
          <BanerIconWrapper>
            <BanerIcon src={image} />
          </BanerIconWrapper>
          <BannerHeader>
            <TitleSvg
              image={PipeSvg}
              bottom="-30%"
              left="-10%"
              duration="3s"
              rotation="100deg"
            />
            {header}
          </BannerHeader>
          <BannerParagraph>{subHeader}</BannerParagraph>
          <ButtonWrapper>
            <PrimaryButton type={`${buttonIcon} right_arrow`} to={slug}>
              {buttonText}
            </PrimaryButton>
          </ButtonWrapper>
        </Wrapper>
      </SectionWrapper>
    </Container>
  )
}

export { BanerSmall }
