import { useStaticQuery, graphql } from "gatsby"

export const useMissionPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      image_1: file(relativePath: { eq: "img/AboutUs/box-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 480, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image_2: file(relativePath: { eq: "img/AboutUs/box-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 480, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image_3: file(relativePath: { eq: "img/AboutUs/box-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 480, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return query
}
