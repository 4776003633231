import styled from "styled-components"
import { SectionWrapper } from "shared/Styles/Wrappers"
import { HashHeader } from "components/Typography"

export const StyledHash = styled(HashHeader)`
  font-size: 80px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 60px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 30px;
  }
`
export const Container = styled.div`
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 200px;
  }
`

export const ImageBox = styled.div`
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .gatsby-image-wrapper {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      height: 200px;
    }
  }
`

export const Wrapper = styled(SectionWrapper)`
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 3;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    justify-content: center;
  }
`
