import styled from "styled-components"
import BackgroundImg from "assets/img/svg/bg_6.svg"
import { Paragraph } from "components/Typography"

export const Container = styled.div`
  padding: 64px 0;
  background-color: ${({ theme }) => theme.colors.purple};
  background-image: url(${BackgroundImg});
  background-size: auto 100%;
  background-position: left top;
  background-repeat: no-repeat;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
`

export const Wrapper = styled.div`
  position: relative;
  width: 600px;
  margin: 0 auto;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: 400px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 300px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_small}) {
    width: 250px;
  }
`

export const BanerIconWrapper = styled.div`
  width: 320px;
  height: 180px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 240px;
    height: 140px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: 180px;
    height: 100px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 160px;
    height: 80px;
  }
`

export const BanerIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const BannerHeader = styled.h2`
  font-size: ${({ size }) => (size ? size : "30px")};
  font-weight: bold;
  font-family: "Foco", sans-serif;
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.white)};
  text-align: center;
  position: relative;
  margin-top: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 27px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 25px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 20px;
    max-width: 70%;
    margin: 36px auto 0;
  }
`

export const BannerParagraph = styled(Paragraph)`
  text-align: center;
  font-weight: bold;
  font-size: ${({ size }) => (size ? size : "22px")};
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.white)};
  margin-top: 15px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 16px;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 45px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-top: 20px;
  }
`
