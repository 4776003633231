import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"

import BackgroundImage from "assets/img/svg/bg_5.svg"

export const Container = styled(GlobalWrapper)`
  background-color: #5618a4;
  background-image: url(${BackgroundImage});
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 96px 0;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`
export const Wrapper = styled(SectionWrapper)``

export const TextWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 24px auto;
  text-align: center;
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 64px 0;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 48px 0 24px 0;
  }
`
export const ItemWrapper = styled.div`
  position: relative;
  width: calc(33.33% - 18px);
  &:not(:nth-child(3n)) {
    margin-right: 27px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 100%;
    max-width: 400px;
    width: 100%;
    margin: 0 auto 24px auto !important;
  }
`

export const Item = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`

export const ImageBox = styled.div`
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
  }
  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`

export const Mask = styled.div``

export const Text = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 20px;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`
