import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"

export const Container = styled(GlobalWrapper)`
  padding: 96px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`
export const Wrapper = styled(SectionWrapper)``

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const ItemsWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 48px;
  }
`

export const Item = styled.div`
  width: calc(33.33% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 36px;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  position: relative;
  &:not(:nth-child(3n)) {
    margin-right: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 36px 24px;
    width: calc(33.33% - 12px);
    &:not(:nth-child(3n)) {
      margin-right: 18px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto !important;
    margin-bottom: 24px;
  }
`

export const IconBox = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 80px;
    height: 80px;
  }
`
export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`
export const Count = styled.div`
  margin: 36px 24px;
  font-weight: 700;
  font-size: 50px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 24px 12px;
    font-size: 34px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 30px;
  }
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`
