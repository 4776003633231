import React from "react"
import { SectionWrapper } from "shared/Styles/Wrappers"
import { TitleSvg } from "components/TitleSvg"
import { SubHeaderParagraph, SubHeaderTitle } from "components/Typography"
import {
  Container,
  SubHeaderImg,
  SubHeaderImgWrapper,
  SubHeaderWrapper,
} from "./styles"

import PipeSvg from 'assets/img/PageHeader/pipe.svg';
import PlusSvg from 'assets/img/PageHeader/x.svg';

const PageSubHeader = ({ image, title, children, align }) => {
  return (
    <Container align={align}>
      <SectionWrapper>
        {image && (
          <SectionWrapper>
            <SubHeaderWrapper>
              <SubHeaderImgWrapper>
                <TitleSvg image={PipeSvg} bottom="-10%"   right="-50%" rotation="-20deg" duration="5s"></TitleSvg>
                <TitleSvg image={PlusSvg} left="-40%" top="5%" rotation="0deg" duration="3s"></TitleSvg>
                <SubHeaderImg src={image}></SubHeaderImg>
              </SubHeaderImgWrapper>
            </SubHeaderWrapper>
          </SectionWrapper>
        )}
        <SubHeaderTitle size="36px">{title}</SubHeaderTitle>
        <SubHeaderParagraph>{children}</SubHeaderParagraph>
      </SectionWrapper>
    </Container>
  )
}

export { PageSubHeader }
