import React from "react"
import { SubHeaderTitle } from "components/Typography"

import {
  Container,
  Wrapper,
  TitleWrapper,
  TeamPhoto,
  ItemsWrapper,
  Item,
  ImageBox,
  Name,
  Proffesion,
} from "./styles"

import { useTeamData } from "shared/Hooks/Team/useTeamData"
import { TitleSvg } from "components/TitleSvg"

import PipeSvg from "assets/img/PageHeader/pipe.svg"
import CircleSvg from "assets/img/PageHeader/o.svg"
import CrossSvg from "assets/img/PageHeader/x.svg"

export const Team = () => {
  const data = useTeamData()

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <SubHeaderTitle>Poznaj nasz zespół</SubHeaderTitle>
        </TitleWrapper>
        <TeamPhoto>
          {data.hero}

          <TitleSvg
            image={CrossSvg}
            top="15%"
            right="30%"
            duration="4s"
            rotation="70deg"
          />
          <TitleSvg image={PipeSvg} bottom="25%" left="25%" duration="3s" />
        </TeamPhoto>
        <ItemsWrapper>
          {data.team.map((item, i) => (
            <Item key={i}>
              <ImageBox>{item.image}</ImageBox>
              <Name>{item.name}</Name>
              <Proffesion>{item.proffesion} </Proffesion>
            </Item>
          ))}
          <TitleSvg
            image={PipeSvg}
            bottom="15%"
            left="15%"
            duration="5s"
            rotation="100deg"
          />
          <TitleSvg image={CircleSvg} top="44%" right="30%" duration="3s" />
        </ItemsWrapper>
      </Wrapper>
    </Container>
  )
}
