import { useStaticQuery, graphql } from "gatsby"

export const useTeamPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "img/Team/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1470, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mariusz: file(relativePath: { eq: "img/Team/mariusz.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      magda: file(relativePath: { eq: "img/Team/magda.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      patrycja: file(relativePath: { eq: "img/Team/patrycja.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      patryk: file(relativePath: { eq: "img/Team/patryk.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      rafal_s: file(relativePath: { eq: "img/Team/rafal_s.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      rafal_k: file(relativePath: { eq: "img/Team/rafal_k.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      justyna: file(relativePath: { eq: "img/Team/justyna.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      alex: file(relativePath: { eq: "img/Team/alex.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      dariusz: file(relativePath: { eq: "img/Team/dariusz.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return query
}
