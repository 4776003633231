import React from "react"

import {
  Container,
  Wrapper,
  TitleWrapper,
  ItemsWrapper,
  Item,
  IconBox,
  Icon,
  Count,
  Title,
} from "./styles"

import BoyIcon from "assets/img/svg/boy_2.svg"
import HeartIcon from "assets/img/svg/heart_2.svg"
import PresentIcon from "assets/img/svg/present_2.svg"

import { TitleSvg } from "components/TitleSvg"
import { SubHeaderTitle } from "components/Typography"

import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import CircSvg from "assets/img/PageHeader/o.svg"

const data = [
  {
    icon: BoyIcon,
    count: 26,
    title: "Podopiecznych w naszej fundacji",
    icons: <TitleSvg image={PlusSvg} top="20%" left="15%" duration="3s" />,
  },
  {
    icon: HeartIcon,
    count: 568,
    title: "Darczyńców wspomogło do tej pory naszą fundację",
    icons: (
      <TitleSvg
        image={PipeSvg}
        bottom="5%"
        right="5%"
        duration="3s"
        rotation="80deg"
      />
    ),
  },
  {
    icon: PresentIcon,
    count: 3,
    title: "Przeprowadzone akcje",
    icons: <TitleSvg image={CircSvg} top="20%" right="15%" duration="5s" />,
  },
]

export const Counts = () => {
  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <SubHeaderTitle>Fundacja w liczbach</SubHeaderTitle>
        </TitleWrapper>
        <ItemsWrapper>
          {data.map((item, i) => (
            <Item key={i}>
              {item.icons}
              <IconBox key={i}>
                <Icon src={item.icon} alt="ikona" />
              </IconBox>
              <Count>{item.count}</Count>
              <Title>{item.title}</Title>
            </Item>
          ))}
        </ItemsWrapper>
      </Wrapper>
    </Container>
  )
}
