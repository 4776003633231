import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"

export const Container = styled(GlobalWrapper)`
  padding: 96px 0 66px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0 51px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0 38px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0 24px 0;
  }
`
export const Wrapper = styled(SectionWrapper)`
  position: relative;
`

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export const ItemsWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 48px;
  }
`
export const TeamPhoto = styled.div`
  margin: 48px 0;
  width: 100%;
  position: relative;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`

export const Item = styled.div`
  width: calc(20% - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 48px;

  &:not(:nth-child(5n)) {
    margin-right: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 24px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: calc(25% - 24px);
    &:not(:nth-child(5n)) {
      margin-right: 0px;
    }
    &:not(:nth-child(4n)) {
      margin-right: 32px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: calc(50% - 12px);
    &:not(:nth-child(4n)) {
      margin-right: 0px;
    }
    &:not(:nth-child(2n)) {
      margin-right: 24px;
    }
  }
`

export const ImageBox = styled.div`
  width: 160px;
  height: 160px;
  border: 1px solid #efefef;
  overflow: hidden;
  padding: 5px;
  border-radius: 100%;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    padding: 5px;
    overflow: hidden;
    border-radius: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 130px;
    height: 130px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100px;
    height: 100px;
  }
`

export const Name = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
    margin: 12px 0 4px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`

export const Proffesion = styled.div`
  font-size: 14px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 10px;
  }
`
