import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Container, ImageBox, StyledHash, Wrapper } from "./styles"

export const Hero = () => {
  const query = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "img/AboutUs/o-nas-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1928, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Container>
      <ImageBox>
        <Image
          objectFit="contain"
          imgStyle={{ width: "100%", height: "100%" }}
          fluid={query.hero.childImageSharp.fluid}
        />
      </ImageBox>
      <Wrapper>
        <StyledHash color="#fff">światbezbarier</StyledHash>
      </Wrapper>
    </Container>
  )
}
