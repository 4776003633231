import React from "react"
import { useTeamPhotos } from "./useTeamPhotos"
import Image from "gatsby-image"

export const useTeamData = () => {
  const image = useTeamPhotos()
  const data = {
    hero: <Image fluid={image.hero.childImageSharp.fluid} />,
    team: [
      {
        image: <Image fluid={image.mariusz.childImageSharp.fluid} />,
        name: "Mariusz Trzeciak",
        proffesion: "Head of CEO Foundation",
      },
      {
        image: <Image fluid={image.magda.childImageSharp.fluid} />,
        name: "Magdalena Małek",
        proffesion: "Koordynator Projektu",
      },
      {
        image: <Image fluid={image.patrycja.childImageSharp.fluid} />,
        name: "Patrycja Bilska",
        proffesion: "Project Manager",
      },
      {
        image: <Image fluid={image.patryk.childImageSharp.fluid} />,
        name: "Patryk Białas",
        proffesion: "Designer",
      },
      {
        image: <Image fluid={image.rafal_s.childImageSharp.fluid} />,
        name: "Rafał Skórka",
        proffesion: "Developer",
      },
      {
        image: <Image fluid={image.rafal_k.childImageSharp.fluid} />,
        name: "Rafał Krawiec",
        proffesion: "Developer",
      },
      {
        image: <Image fluid={image.justyna.childImageSharp.fluid} />,
        name: "Justyna",
        proffesion: "Copywriter",
      },
      {
        image: <Image fluid={image.alex.childImageSharp.fluid} />,
        name: "Alex Mechew",
        proffesion: "Social Media",
      },
    ],
  }
  return data
}
