import styled from "styled-components"
import {SubHeaderTitle} from '../Typography';
import {Paragraph} from '../Typography';

export const Container = styled.div`
    background: ${({theme}) => theme.colors.white};
    padding: 60px 0;
    text-align: center;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    a {
        text-decoration: underline;
        color: ${({theme}) => theme.colors.accentColor};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 48px 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 36px 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        padding: 24px 0;
    }
`;

export const SubHeaderWrapper = styled.div`
    margin-bottom: 50px;
`

export const SubHeaderImgWrapper = styled.div`
    display: flex;
    width: 210px;
    height: 110px;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        max-width: 180px;
        width: 100%;
        height: auto;
    }
`

export const SubHeaderImg = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
`;

export const PackageTitle = styled(SubHeaderTitle)`
    margin-bottom: 12px;
    text-align: left;
`;

export const PackageDescription = styled.div`
    text-align: left;

    p:first-child {
        margin-bottom: 36px;
    }

    p:last-child {
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        p:first-child {
            margin-bottom: 24px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        p:first-child {
            margin-bottom: 16px;
        }
    }
`;

export const ListWrapper = styled.div`
    margin-bottom: 48px;
    text-align: left;

    &:last-child {
        margin-bottom: 0;

        ul {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-bottom: 36px;
    }
`;

export const ListTitle = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 16px;
    }
`;

export const List = styled.ul`
    margin: 24px 0;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin: 16px 0;
    }
`;

export const ListItem = styled(Paragraph)`
    position: relative;
    padding-left: 18px;

    &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({theme}) => theme.colors.accentColor};
        position: absolute;
        top: 8px;
        left: 0;
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding-left: 12px;
        &:before {
            width: 4px;
            height: 4px;
            top: 6px;
        }
    }
`;

export const StyledParagraph = styled(Paragraph)`
    sup {
        color: ${({theme}) => theme.colors.red};
    }
`;
