import React from "react"
import { useStoryPhotos } from "./useStoryPhotos"
import Image from "gatsby-image"

import { TitleSvg } from "components/TitleSvg"
import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import CircSvg from "assets/img/PageHeader/o.svg"

export const useStoryData = () => {
  const image = useStoryPhotos()
  const data = [
    {
      image: <Image fluid={image.story_1.childImageSharp.fluid} />,
      date: "Grudzień 2020",
      title: "Narodziny fundacji w Rzeszowie",
      icons: (
        <>
          <TitleSvg
            image={PipeSvg}
            top="10%"
            left="5%"
            rotation="-20deg"
            duration="3s"
          ></TitleSvg>{" "}
        </>
      ),
    },
    {
      image: <Image fluid={image.story_2.childImageSharp.fluid} />,
      date: "Grudzień 2020",
      title: "Finał naszej pierwszej akcji",
      icons: (
        <>
          <TitleSvg
            image={PlusSvg}
            bottom="10%"
            left="10%"
            rotation="90deg"
            duration="4s"
          ></TitleSvg>
          <TitleSvg
            image={CircSvg}
            top="20%"
            right="5%"
            rotation="45deg"
            duration="2s"
          ></TitleSvg>
        </>
      ),
    },
  ]
  return data
}
