import React from "react"
import { useMissionPhotos } from "./useMissionPhotos"
import Image from "gatsby-image"

import { TitleSvg } from "components/TitleSvg"
import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"

export const useMissionData = () => {
  const image = useMissionPhotos()
  const data = [
    {
      image: <Image fluid={image.image_1.childImageSharp.fluid} />,
      title: "pomagamy",
      icons: (
        <>
          <TitleSvg image={PlusSvg} top="-10%" left="30%" duration="5s" />
        </>
      ),
    },
    {
      image: <Image fluid={image.image_2.childImageSharp.fluid} />,
      title: "wspieramy",
    },
    {
      image: <Image fluid={image.image_3.childImageSharp.fluid} />,
      date: "Grudzień 2020",
      title: "motywujemy",
      icons: (
        <>
          <TitleSvg
            image={PipeSvg}
            bottom="-20%"
            right="10%"
            duration="3s"
            rotation="110deg"
          />
        </>
      ),
    },
  ]
  return data
}
