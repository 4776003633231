import styled from "styled-components"
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers"

export const Container = styled(GlobalWrapper)`
  background-color: #f6f6f6;
  padding: 96px 0;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 48px 0;
  }
`

export const Wrapper = styled(SectionWrapper)``

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 48px;
  }
`

export const ItemsWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 800px;
  }
`

export const Item = styled.div`
  width: calc(50% - 24px);
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 48px;
  &:not(:last-child) {
    margin-right: 48px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 36px 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: calc(50% - 12px);
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
  @media screen and (max-width: 546px) {
    width: 100%;
    margin-right: 0 !important;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`

export const ImageBox = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 180px;
    height: 180px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 120px;
    height: 120px;
  }
`

export const TextBox = styled.div`
  text-align: center;
  margin-top: 24px;
`

export const Date = styled.div`
  font-size: 20px;
  font-weight: 300;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-top: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 18px;
    margin-top: 4px;
  }
`
